import "./index.css";

const HomePage = () => {
  return (
    <div className="coming-soon">
    
      <span className="coming-soon-text">Coming soon</span>
    </div>
  );
};

export default HomePage;
