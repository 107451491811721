import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";

const appRouter = (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<App />} />

      <Route exact path="*" element={<App />} />
    </Routes>
  </BrowserRouter>
);

export default appRouter;
